import * as React from "react";
import Layout from "../../components/Layout/Layout";
import shortCrookedLine from "../../assets/img/short-crooked-line.svg";
import "./candidate-privacy-policy.scss";

const CandidatePrivacyPolicyPage = () => {
  return (
    <Layout>
      <h1 className="error-title-privacy-policy-page">Candidate Privacy Policy</h1>
      <img src={shortCrookedLine} className="privacy-policy-line" />

      <div className="candidate-privacy-policy-page-container">
        <p>
          When you apply to join the Nando’s family, we (and other people on our behalf) will
          process personal information about you. This Privacy Notice tells you what to expect in
          relation to your personal data which is collected, handled and processed by or on behalf
          of Nando’s in relation to the recruitment process. Nando’s Chickenland Ltd is a “data
          controller”, which means that we are responsible for deciding how we hold and use personal
          information about you. Our address is St Marys House, 42 Vicarage Crescent, London, SW11
          3LD
        </p>
        <p>
          Any personal data of yours that we handle will be processed in accordance with applicable
          data protection laws.
        </p>
        <h2>What the law says</h2>
        <p>
          We will comply with data protection law. This says that the personal information we hold
          about you must be:
        </p>
        <ul>
          <li>Used lawfully, fairly and in a transparent way.</li>
          <li>
            Collected only for valid purposes that we have clearly explained to you and not used in
            any way that is incompatible with those purposes.
          </li>
          <li>
            Relevant to the purposes we have told you about and limited only to those purposes.
          </li>
          <li>Accurate and kept up to date.</li>
          <li>Kept only as long as necessary for the purposes we have told you about.</li>
          <li>Kept securely.</li>
        </ul>
        <h2>The information we may collect</h2>
        <p>
          In connection with your application for work with us, we may collect, hold and process the
          following categories of personal information about you:
        </p>
        <ul>
          <li>Name</li>
          <li>Address</li>
          <li>Date of birth</li>
          <li>Email address</li>
          <li>Telephone number</li>
          <li>CV/work history</li>
          <li>Qualifications</li>
          <li>Current salary</li>
          <li>Current notice period</li>
          <li>Job preferences including role, geographical areas and salary</li>
          <li>Whether you have a National Insurance Number and UK bank account</li>
          <li>
            Information about whether you have had any assets seized under the Civil Recovery Scheme
          </li>
          <li>
            Any information you provide to us during an interview or during a psychometric test
          </li>
        </ul>
        <p>
          We may also collect, hold and process the following “special categories” of more sensitive
          personal information:
        </p>
        <ul>
          <li>Whether you need a permission to work in the UK</li>
          <li>Whether you consider yourself disabled or have a long term health condition</li>
          <li>
            Whether you have suffered from particular illnesses, allergies or medical conditions
          </li>
          <li>Your gender, ethnic origin, nationality, religion or belief, sexual orientation</li>
          <li>Information about any relevant criminal convictions</li>
        </ul>
        <h2>How is your personal information is collected?</h2>
        <p>We may collect personal information about you from the following sources:</p>
        <ul>
          <li>
            You, the candidate, for example if you apply for a job on the Nando’s website, if you
            fill in one of our application forms or if you hand us your CV in person
          </li>
          <li>Recruitment agencies</li>
          <li>Job boards and publicly accessible sources such as LinkedIn</li>
          <li>Your named referees</li>
          <li>Organisations we partner with to support employment opportunities</li>
        </ul>
        <h2>How we use the information</h2>
        <p>The above information is used to find you suitable work at Nando’s, including:</p>
        <ul>
          <li>To find out more about your skills and experience</li>
          <li>
            To match your skills with job vacancies to assist in finding you the positions that most
            suit you
          </li>
          <li>To carry out background and reference checks, where applicable</li>
          <li>To communicate with you about the recruitment process</li>
          <li>To comply with legal or regulatory requirements</li>
          <li>To keep you informed of available opportunities as they arise</li>
        </ul>
        <p>
          It is in our legitimate interests to decide whether to offer you a job with the Nando’s
          family, as it is beneficial to our business to recruit new employees to grow our business
          and to fill vacancies.
        </p>
        <p>
          We also need to process your personal information to decide whether to enter into a
          contract of employment with you.
        </p>
        <h2>If you choose not to provide personal information</h2>
        <p>
          If you do not provide information when requested, which is necessary for us to consider
          your application (such as evidence of qualifications or work history), we will not be able
          to process your application successfully. For example, if we require references for this
          role and you fail to provide us with relevant details, we will not be able to take your
          application further.
        </p>
        <h2>How we use particularly sensitive personal information</h2>
        <p>
          The information relating to whether you consider yourself as disabled is used for the
          purposes of considering whether there are any workplace adjustments that are reasonably
          required.
        </p>
        <p>
          The information about gender, ethnic origin, religion or belief, gender or sexual
          orientation is only used for the purposes of equal opportunities monitoring.
        </p>
        <p>
          The information about any relevant criminal convictions is used in order to assess your
          suitability to join the Nando’s family. We only consider criminal convictions that are not
          spent under the Rehabilitation of Offenders Act.
        </p>
        <p>
          The information relating to whether you need permission to work in the UK is used to
          decide whether we are able to lawfully employ you to work in the UK.
        </p>
        <p>
          The information relating to whether you have had any illnesses, allergies or medical
          conditions is used to decide whether we are able to offer you a job in one of our
          restaurants or working with food.
        </p>
        <h2>Automated decision making</h2>
        <p>
          Automated decision-making takes place when an electronic system uses personal information
          to make a decision without human intervention. We do not currently take any decisions
          about you using wholly automated means.
        </p>
        <p>
          This Privacy Notice will be regularly updated and reflect any subsequent changes to this
          position.
        </p>
        <h2>Disclosure of your information</h2>
        <p>
          Your information may be disclosed to selected third parties outside of Nando’s (such as
          recruiters, our psychometric testing provider, technology suppliers and job boards). It
          may also be necessary to share your data with Government agencies such as HM Revenue and
          Customs, or the Home Office or legal advisors.
        </p>
        <p>
          Where we need to share your personal information with any of these parties, we will ensure
          they meet the required security arrangements and adhere to the relevant regulatory
          requirements before doing so.
        </p>
        <p>
          We may need to transfer your personal information outside the European Union. If we do,
          you can expect a similar degree of protection in respect of your personal information.
        </p>
        <h2>Your duty to inform us of changes</h2>
        <p>
          It is important that the personal information we hold about you is accurate and current.
          Please keep us informed if your personal information changes during our recruitment
          process.
        </p>
        <h2>Your rights</h2>
        <p>Under certain circumstances, by law you have the right to:</p>
        <ul>
          <li>
            Request access to your personal information (commonly known as a “data subject access
            request”). This enables you to receive a copy of the personal information we hold about
            you and to check that we are lawfully processing it.
          </li>
          <li>
            Request correction of the personal information that we hold about you. This enables you
            to have any incomplete or inaccurate information we hold about you corrected.
          </li>
          <li>
            Request erasure of your personal information. This enables you to ask us to delete or
            remove personal information where there is no good reason for us continuing to process
            it. You also have the right to ask us to delete or remove your personal information
            where you have exercised your right to object to processing (see below).
          </li>
          <li>
            Object to processing of your personal information where we are relying on a legitimate
            interest (or those of a third party) and there is something about your particular
            situation which makes you want to object to processing on this ground. You also have the
            right to object where we are processing your personal information for direct marketing
            purposes.
          </li>
          <li>
            Request the restriction of processing of your personal information. This enables you to
            ask us to suspend the processing of personal information about you, for example if you
            want us to establish its accuracy or the reason for processing it.
          </li>
          <li>Request the transfer of your personal information to another party.</li>
        </ul>
        <p>
          If you want to review, verify, correct or request erasure of your personal information,
          object to the processing of your personal data, or request that we transfer a copy of your
          personal information to another party, please send an email to{" "}
          <a href="mailto:peoplesupport@nandos.co.uk ">peoplesupport@nandos.co.uk</a>.
        </p>
        <p>
          You will not have to pay a fee to access your personal information (or to exercise any of
          the other rights). However, we may charge a reasonable fee if your request for access is
          clearly unfounded or excessive. Alternatively, we may refuse to comply with the request in
          such circumstances.
        </p>
        <p>
          We may need to request specific information from you to help us confirm your identity and
          ensure your right to access the information (or to exercise any of your other rights).
          This is anappropriate security measure to ensure that personal information is not
          disclosed to any person who has no right to receive it.
        </p>
        <p>Right to withdraw consent</p>
        <p>
          In the limited circumstances where you may have provided your consent to the collection,
          processing and transfer of your personal information for the purposes of recruitment, you
          have the right to withdraw your consent for that specific processing at any time. To
          withdraw your consent, please contact{" "}
          <a href="mailto:peoplesupport@nandos.co.uk">peoplesupport@nandos.co.uk</a>. Once we have
          received notification that you have withdrawn your consent, we will no longer process your
          application and, subject to our retention policy, we will dispose of your personal data
          securely.
        </p>
        <h2>Retention of your data</h2>
        <p>
          Your data will be retained for no longer than is necessary and in accordance with our Data
          Retention Policy which can be obtained from{" "}
          <a href="mailto:peoplesupport@nandos.co.uk">peoplesupport@nandos.co.uk</a>.
        </p>
        <p>
          If you have applied for a job with Nando’s or you have sent us your CV but we did not have
          a suitable job available for you, then we will keep your personal data (including a copy
          of your CV/application form) on file for 6 months in case a suitable alternative vacancy
          arises and if so, we may contact you to find out if you are interested in the vacancy. If
          no suitable vacancies arise within 6 months then we will securely destroy your data in
          accordance with our Data Retention Policy. If we destroy your data, this does not prevent
          you from applying for a job with us in the future.
        </p>
        <p>
          It is in our legitimate interests to contact you about potentially suitable alternative
          roles where you have applied for a job with us in the past, as it is beneficial to our
          business to recruit new employees to grow our business and to fill vacancies.
        </p>
        <p>
          If you do not wish us to retain your data in case a suitable alternative vacancy arises,
          please contact us at{" "}
          <a href="mailto:peoplesupport@nandos.co.uk">peoplesupport@nandos.co.uk</a>.
        </p>
        <h2>Who to contact</h2>
        <p>
          If you have any questions or concerns regarding the processing of your personal
          information by Nando’s, you can contact us on the following email address{" "}
          <a href="mailto:peoplesupport@nandos.co.uk">peoplesupport@nandos.co.uk</a>.
        </p>
        <p>
          The Information Commissioner's Office (ICO) is the supervisory authority in the UK for
          data protection issues. Further information on data protection (including your right to
          complain about the use of your personal data) can be found on the ICO’s website{" "}
          <a href="https://www.ico.org.uk">www.ico.org.uk</a>.{" "}
        </p>
      </div>
    </Layout>
  );
};

export default CandidatePrivacyPolicyPage;

export const Head = () => <meta name="robots" content="noindex" />;
